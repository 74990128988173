import axios from "axios"
// import { setWorkerInfo } from "../features/worker/workerSlice";
import { tokenConfig } from '../helpers/setAuthToken';
import { setIncorrectPassword, setPasswordRequested } from "../features/report/reportSlice";
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer';
import { Capacitor } from "@capacitor/core"
import { download } from "../helpers/download";
import { open } from "../helpers/open";
import { setExpiredSession } from "../helpers/expiredSession"

export interface IReportStatus {
    incorrectPassword: boolean;
    passwordRequested: boolean;
    reportDownloaded: boolean;
};


export const requestReportPassword = (props:any) => async (dispatch:any) => {
    const serverUrl = localStorage.getItem("server_url")

    try {
        await axios.get<any>( 
            `${serverUrl}/api/request_report_password/?report=${props.report_type}&report-id=${props.report_id}`,
            tokenConfig()
        );

        dispatch(setPasswordRequested())

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            if (error.response?.data.code == "token_not_valid") {
                setExpiredSession()
            }
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

export const requestReport = (props:any) => async (dispatch:any) => {
    const serverUrl = localStorage.getItem("server_url")
    
    try {
        // Form Data
        const form_data = new FormData();
        form_data.append('password', props.password);

        const response = await axios.post<any>( 
            `${serverUrl}/api/request_report/?report=${props.report_type}&report-id=${props.report_id}`,
            form_data,
            tokenConfig('blob'),
        );
        const blobfile = new Blob([response.data], { type: "application/pdf" })
        const url = window.URL.createObjectURL(blobfile);
        const platform = Capacitor.getPlatform()
        if (platform === "web") {
            const link = document.createElement('a');
            link.href = url;
            if (props.report_name!==undefined) {
                link.setAttribute('download', props.report_name); 
            } else {
                link.setAttribute('download', 'file.pdf'); 
            }
            document.body.appendChild(link);
            link.click();
        } else {
            await download(blobfile, props.report_name, dispatch)
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.response);
            if (error.response?.statusText == "Unauthorized") {
                setExpiredSession()
            } else if (error.response?.statusText == "Forbidden") {
                dispatch(setIncorrectPassword())
            }
            
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}