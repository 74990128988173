import "./ModalCreateAppointment.scss"

import { IonButton, IonButtons, IonHeader, IonIcon, IonLabel, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, } from '@ionic/react';
import { closeOutline,} from 'ionicons/icons';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addAppointment } from "../../actions/appointmentManagement";
import { useAppDispatch } from "../../app/hooks";


const ModalCreateAppointment = ({
    onDismiss, date
    }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
        date : Date
    }) => {       

    const [t] = useTranslation("global");

    const types = [
        { name : 'Primera Consulta', value : 'first' },
        { name : 'Revisión'        , value : 'revision' },
        { name : 'Otros Motivos'   , value : 'other' },
    ]

    const departments = [
        { name : 'Alergología', value : 0},
        { name : 'Anatomía Patológica', value : 1},
        { name : 'Anestesiología y Reanimación', value : 2},
        { name : 'Angiología y Cirugía Vascular', value : 3},
        { name : 'Aparato Digestivo', value : 4},
        { name : 'Cardiología', value : 5},
        { name : 'Cirugía Cardiovascular', value : 6},
        { name : 'Cirugía General y del Aparato Digestivo', value : 7},
        { name : 'Cirugía Oral y Maxilofacial', value : 8},
        { name : 'Cirugía Ortopédica y Traumatología', value : 9},
        { name : 'Cirugía Pediátrica', value : 10},
        { name : 'Cirugía Plástica, Estética y Reparadora', value : 11},
        { name : 'Cirugía Torácica', value : 12},
        { name : 'Dermatología Médico-Quirúrgica y Venereología', value : 13},
        { name : 'Endocrinología y Nutrición', value : 14},
        { name : 'Farmacología Clínica', value : 15},
        { name : 'Geriatría', value : 16},
        { name : 'Hematología y Hemoterapia', value : 17},
        { name : 'Inmunología', value : 18},
        { name : 'Medicina del Trabajo', value : 19},
        { name : 'Medicina Familiar y Comunitaria', value : 20},
        { name : 'Medicina Física y Rehabilitación', value : 21},
        { name : 'Medicina Intensiva', value : 22},
        { name : 'Medicina Interna', value : 23},
        { name : 'Medicina Nuclear', value : 24},
        { name : 'Medicina Preventiva y Salud Pública', value : 25},
        { name : 'Nefrología', value : 26},
        { name : 'Neumología', value : 27},
        { name : 'Neurocirugía', value : 28},
        { name : 'Neurofisiología Clínica', value : 29},
        { name : 'Neurología', value : 30},
        { name : 'Obstetricia y Ginecología', value : 31},
        { name : 'Oftalmología', value : 32},
        { name : 'Oncología Médica', value : 33},
        { name : 'Oncología Radioterápica', value : 34},
        { name : 'Otorrinolaringología', value : 35},
        { name : 'Pediatría y sus Áreas Específicas', value : 36},
        { name : 'Psiquiatría', value : 37},
        { name : 'Radiodiagnóstico', value : 38},
        { name : 'Reumatología', value : 39},
        { name : 'Urología', value : 40},
    ]

    const medics = [
        { name : 'Dra. Ana Mª Regalado Hernandez'  , value : 'ana-regalado' },
        { name : 'Dra. Belén Lopez Castañeda'      , value : 'belen-lopez' },
        { name : 'Dra. Shara García Rodríguez'     , value : 'shara-garcia' },
        { name : 'Dra. Lorena Mª Rodríguez Martín' , value : 'lorena-rodriguez' },
        { name : 'Dr. Jesús Marchante Marchante'   , value : 'jesus-marchante' },
        { name : 'Dr. Jesús García Gonzalez'       , value : 'jesus-garcia' },
        { name : 'Dr. Adrián Marchante Marchante'  , value : 'adrian-marchante' },
        { name : 'Dr. Daniel Rello Rosa'           , value : 'daniel-rello' },
    ]

    const hospitals = [
        { name : 'Hospital General Universitario Gregorio Marañón', value : 0 },
        { name : 'Hospital Universitario Fundación Jiménez Díaz', value : 1 },
        { name : 'Hospital El Escorial', value : 2 },
        { name : 'Hospital Dr. Rodriguez Lafora', value : 3 },
        { name : 'Hospital de Guadarrama', value : 4 },
        { name : 'Hospital Clínico San Carlos', value : 5 },
        { name : 'Hospital Central de la Defensa "Gómez Ulla"', value : 6 },
        { name : 'Hospital Central de la Cruz Roja San José y Santa Adela', value : 7 },
    ]

    const times = [
        '09:00', '09:15', '09:30', '09:45', 
        '10:00', '10:15', '10:30', '10:45',
        '11:00', '11:15', '11:30', '11:45',
        '12:00', '12:15', '12:30', '12:45',
        '13:00', '13:15', '13:30', '13:45',
        '14:00', '14:15', '14:30', '14:45',
        '15:00', '15:15', '15:30', '15:45',
        '16:00', '16:15', '16:30', '16:45',
        '17:00', '17:15', '17:30', '17:45',
        '18:00', '18:15', '18:30', '18:45',
        '19:00', '19:15', '19:30', '19:45',
    ]

    const dispatch = useAppDispatch()
    
    function handleFormSubmit( e : React.SyntheticEvent ){
        e.preventDefault();
        const form     = e.target as HTMLFormElement;
        const formData = new FormData(form);

        const typeValue       = formData.get('type') as string;
        const specialityValue = formData.get('speciality') as string;
        const medicValue      = formData.get('medic') as string;
        const hospitalValue   = formData.get('hospital') as string;

        const type       = types.find( t => t.value === typeValue )!.name
        const speciality = departments.find( d => d.value === +specialityValue )!.name
        const medic      = medics.find( m => m.value === medicValue )!.name
        const hospital   = hospitals.find( h => h.value === +hospitalValue )!.name
        const time       = formData.get('time') as string
        
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        const appointment_date = `${year}-${month}-${day}T${time}:00`;

        const newAppointment = {
            id: Math.floor(Math.random() * 1000),
            appointment_type: type,
            appointment_decline_reason: '',
            date: appointment_date,
            main_reason: speciality,
            medic,
            medical_center: hospital,
            status: 'programmed',
        }

        dispatch(addAppointment( newAppointment ))
        onDismiss(null, 'added')
    }

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton className='user-profile__close-button' title={t('Salir')} color="medium" onClick={() => onDismiss(null, 'cancel')}>
                <IonIcon className='user-profile__close' ios={closeOutline} md={closeOutline}/>
              </IonButton>
            </IonButtons>
            <IonTitle>{t('Crear una comunicación')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="ion-padding create-notif">
            <form action="" onSubmit={(e) => handleFormSubmit(e)} className='modal-form'>
                <ul className="modal-form__ul">
                    <li>
                        <IonLabel>{t('Típo')}*</IonLabel>
                        <IonSelect name="type" value={'first'} okText={t('Seleccionar Típo')} cancelText={t('Cancelar')} labelPlacement="floating" className='modal-form__select' placeholder="Típo de Consulta">
                            {
                                types.map( type => {
                                    return <IonSelectOption value={ type.value }>{ type.name }</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    </li>
                    <li>
                        <IonLabel>{t('Especialidad')}*</IonLabel>
                        <IonSelect name="speciality" value={0} okText={t('Seleccionar Especialidad')} cancelText={t('Cancelar')} labelPlacement="floating" className='modal-form__select' placeholder="Elegir Especialidad">
                            {
                                departments.map( department => {
                                    return <IonSelectOption value={ department.value }>{ department.name }</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    </li>
                    <li>
                        <IonLabel>{t('Médico')}*</IonLabel>
                        <IonSelect name="medic" value={'ana-regalado'} okText={t('Seleccionar Médico')} cancelText={t('Cancelar')} labelPlacement="floating" className='modal-form__select' placeholder="Elegir Médico">
                            {
                                medics.map( medic => {
                                    return <IonSelectOption value={ medic.value }>{ medic.name }</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    </li>
                    <li>
                        <IonLabel>{t('Centro Médico')}*</IonLabel>
                        <IonSelect name="hospital" value={0} okText={t('Seleccionar Centro Médico')} cancelText={t('Cancelar')} labelPlacement="floating" className='modal-form__select' placeholder="Elegir Centro Médico">
                            {
                                hospitals.map( hospital => {
                                    return <IonSelectOption value={ hospital.value }>{ hospital.name }</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    </li>
                    <li>
                        <IonLabel>{t('Hora')}*</IonLabel>
                        <IonSelect name="time" value={'09:00'} okText={t('Seleccionar Hora')} cancelText={t('Cancelar')} labelPlacement="floating" className='modal-form__select' placeholder="Elegir Hora">
                            {
                                times.map( time => {
                                    return <IonSelectOption value={ time }>{ time }</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    </li>
                </ul>
                <button className="button-base solid modal-form__submit" type='submit' title={t('Añadir Cita')}>{t('Añadir Cita')}</button>
            </form>
        </div>
      </IonPage>
    );
  };


  export default ModalCreateAppointment