import { IonPage, IonContent } from '@ionic/react'
import { useAppSelector } from "../app/hooks";
import { selectWorkerHealthReports } from "../features/worker/workerSlice";
import Report from '../components/Report'
import MedicalHistoryDocument from '../components/MedicalHistoryDocument'
import './Vigilancia.css'
import Advice from '../components/Advice';
import Aptitude from '../components/Aptitude';
import Toolbar from '../components/Toolbar';
import { useTranslation } from 'react-i18next';

const Vigilancia : React.FC = ()=>{
    // const healthReports = useAppSelector(selectWorkerHealthReports);
    const [t, i18n] = useTranslation("global");

    const healthReports = [
        {
            "id": 91636,
            "file_type": "medical_consultations",
            "report_type": "Examen de salud",
            "file_name": "consulta_91636_2024_01_31_13_10.pdf",
            "date": "2024-01-31T13:10:00",
            "status": "closed",
            "main_reason": "RECONOCIMIENTO PERIODICO ORDINARIO",
            "free_advices": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sem nunc, maximus ut libero id, pulvinar sagittis ligula. Mauris nec eleifend eros, non dignissim sem. Mauris vitae mi nunc. Maecenas vulputate urna nec leo mattis porta. Mauris sollicitudin libero ut iaculis iaculis. Morbi sit amet leo orci. Phasellus varius eget leo eu pretium. Praesent id urna tellus. Etiam porta, mauris id placerat placerat, urna ligula bibendum erat, ut ullamcorper erat nulla non ex. Nulla dignissim viverra ex ac consectetur. Nunc maximus diam nec dui tincidunt auctor. Cras eu ligula nisl. Quisque id consequat est, sed posuere massa. Donec rhoncus consequat pharetra.</p>",
            "medical_center": "HOSPITAL VIRGEN DEL CONSUELO",
            "health_tech": "Elena",
            "show_advices": "True",
            "upload_aptitude_report": true,
            "upload_jaeger_report": true,
            "aptitudes": [
                {
                    "name": "APTA CON RESTRICCION LABORAL ADAPTATIVA",
                    "workstation": "Puesto nuevo",
                    "conditionings": "",
                    "consultation_id": 91636
                },
                {
                    "name": "APTO CON RESTRICCION",
                    "workstation": "El puesto",
                    "conditionings": "",
                    "consultation_id": 91636
                }
            ],
            "jaeger": {
                "id": "",
                "file_type": "jaeger",
                "consultation_id": 91636
            },
            "consultation_files": [],
            "image_test_files": [],
            "ecg_files": [],
            "other_test_files": [],
            "consultation_advices": [
                {
                    "id": 10356,
                    "advice": "ACONSEJAMOS DIETA HIPOLIPEMIANTE Y EJERCICIO"
                },
                {
                    "id": 10355,
                    "advice": "CONTROL POR ANGIOLOGIA VASCULAR"
                },
                {
                    "id": 10357,
                    "advice": "ACONSEJAMOS DIETA HIPOCALORICA DIABETICA"
                },
                {
                    "id": 10358,
                    "advice": "CONTROL POR HEPATOLOGO"
                },
                {
                    "id": 10359,
                    "advice": "ACONSEJAMOS DIETA HIPOURICEMICA"
                },
                {
                    "id": 10360,
                    "advice": "CONTROL POR SU O.R.L"
                },
                {
                    "id": 10361,
                    "advice": "ACONSEJAMOS DIETA HIPOLIPEMIANTE"
                }
            ]
        },
        {
            "id": 91639,
            "file_type": "medical_consultations",
            "report_type": "Examen de salud",
            "file_name": "consulta_91639_2024_01_25_13_21.pdf",
            "date": "2024-01-25T13:21:00",
            "status": "closed",
            "main_reason": "RECONOCIMIENTO PERIODICO ORDINARIO",
            "free_advices": null,
            "medical_center": "HOSPITAL NUEVE DE OCTUBRE",
            "health_tech": "Gina",
            "show_advices": "False",
            "upload_aptitude_report": true,
            "upload_jaeger_report": true,
            "aptitudes": [
                {
                    "name": "APTA CON RESTRICCION LABORAL ADAPTATIVA",
                    "workstation": "Puesto nuevo",
                    "conditionings": "ALTO NIVEL DE RUIDO Y TREPIDACIONES, AMBIENTES PLUVÍGENOS, BIPEDESTACIÓN PROLONGADA, GRANDES ESFUERZOS FÍSICOS",
                    "consultation_id": 91639
                }
            ],
            "jaeger": {
                "id": "",
                "file_type": "jaeger",
                "consultation_id": 91639
            },
            "consultation_files": [
                {
                    "id": 10,
                    "file_name": "archivo-consulta.pdf",
                    "file_type": "consultation_files",
                    "comment": "",
                    "date": "2024-01-25T13:21:00",
                    "consultation_id": 91639
                }
            ],
            "image_test_files": [],
            "ecg_files": [],
            "other_test_files": [],
            "consultation_advices": []
        },
        {
            "id": 91623,
            "file_type": "medical_consultations",
            "report_type": "Examen de salud",
            "file_name": "consulta_91623_2024_01_10_13_43.pdf",
            "date": "2024-01-10T13:43:00",
            "status": "closed",
            "main_reason": "RECONOCIMIENTO PERIODICO ORDINARIO",
            "free_advices": "<p>OSWUHERFUOHWASEDRIUOASRHOAWORIA</p>",
            "medical_center": "Sin centro asociado",
            "health_tech": "Bertha",
            "show_advices": "False",
            "upload_aptitude_report": true,
            "upload_jaeger_report": false,
            "aptitudes": [],
            "jaeger": {
                "id": "",
                "file_type": "jaeger",
                "consultation_id": 91623
            },
            "consultation_files": [
                {
                    "id": 9,
                    "file_name": "archivo-consulta.pdf",
                    "file_type": "consultation_files",
                    "comment": "",
                    "date": "2024-01-10T13:43:00",
                    "consultation_id": 91623
                }
            ],
            "image_test_files": [
                {
                    "id": 2,
                    "file_name": "test-de-imagen.pdf",
                    "file_type": "image_test_files",
                    "comment": "image_test_2",
                    "date": "2024-01-10T13:43:00",
                    "consultation_id": 91623
                }
            ],
            "ecg_files": [
                {
                    "id": 1,
                    "file_name": "electrocardiograma.pdf",
                    "file_type": "ecg_files",
                    "comment": "electrocardiogram_1",
                    "date": "2024-01-10T13:43:00",
                    "consultation_id": 91623
                }
            ],
            "other_test_files": [
                {
                    "id": 2,
                    "file_name": "otro-archivo.pdf",
                    "file_type": "other_test_files",
                    "comment": "other_test_2",
                    "date": "2024-01-10T13:43:00",
                    "consultation_id": 91623
                }
            ],
            "consultation_advices": [
                {
                    "id": 10354,
                    "advice": "ACONSEJAMOS DIETA HIPOCALORICA DIABETICA"
                },
                {
                    "id": 10353,
                    "advice": "CONTROL POR ANGIOLOGIA VASCULAR"
                }
            ]
        },
        {
            "id": 91617,
            "file_type": "medical_consultations",
            "report_type": "Visita",
            "file_name": "consulta_91617_2024_01_08_10_47.pdf",
            "date": "2024-01-08T10:47:00",
            "status": "closed",
            "main_reason": "Consulta",
            "free_advices": null,
            "medical_center": "HOSPITAL NUEVE DE OCTUBRE",
            "health_tech": "Bertha",
            "show_advices": "False",
            "upload_aptitude_report": true,
            "upload_jaeger_report": true,
            "aptitudes": [],
            "jaeger": {
                "id": "",
                "file_type": "jaeger",
                "consultation_id": 91617
            },
            "consultation_files": [],
            "image_test_files": [],
            "ecg_files": [],
            "other_test_files": [],
            "consultation_advices": []
        },
        {
            "id": 91598,
            "file_type": "medical_consultations",
            "report_type": "Examen de salud",
            "file_name": "consulta_91598_2023_12_04_14_45.pdf",
            "date": "2023-12-04T14:45:00",
            "status": "closed",
            "main_reason": "RECONOCIMIENTO PERIODICO ORDINARIO",
            "free_advices": null,
            "medical_center": "Sin centro asociado",
            "health_tech": "Bertha",
            "show_advices": "False",
            "upload_aptitude_report": false,
            "upload_jaeger_report": false,
            "aptitudes": [],
            "jaeger": {
                "id": 3,
                "file_type": "jaeger",
                "consultation_id": 91598
            },
            "consultation_files": [],
            "image_test_files": [],
            "ecg_files": [],
            "other_test_files": [],
            "consultation_advices": []
        },
    ]

    return (
        <>
        <IonPage>
            <Toolbar title={t('Informes')} />
            <IonContent fullscreen>

            <div className="vigil">
                <div className="vigil__body">
                    <div className="vigil__column reports">
                        <div className="reports__head">
                            <div className="reports__title">{t('Informes')}:</div>
                        </div>
                        <div className="reports__body">
                            {healthReports.map((report : any, index : any)=>{
                                if (report.file_type==='aptitude_periods'){
                                    return(
                                        <Aptitude key={index} rep={report}/>
                                    )
                                } else if (report.file_type==='medical_consultations') {
                                    return(
                                        <Report key={index} rep={report}/>
                                    )
                                } else if (report.file_type==='medical_history_documents') {
                                    return(
                                        <MedicalHistoryDocument key={index} rep={report}/>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <div className="vigil__column advice">
                        <div className="reports__head">
                            <div className="reports__title">{t('Consejos')}:</div>
                        </div>
                        <div className="reports__body">
                            {healthReports.map((report:any, index:any)=>{
                                if (report.show_advices==="True") {
                                    return(
                                        <Advice key={index} adv={report}/>
                                    )
                                }
                            }, )}
                        </div>
                    </div>
                </div>
            </div>
            </IonContent>
        </IonPage>
        </>
    )
}




export default Vigilancia
