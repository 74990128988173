import {
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonPage,
  IonLabel,
  IonIcon,
  IonNote,
  useIonModal,
  IonAvatar,
  IonSkeletonText
} from '@ionic/react';
import { OverlayEventDetail } from '@ionic/core/components';

import { useAppSelector } from '../../app/hooks';
import "./ModalUserProfile.css"
import "./ModalChangePassword.css"
import {
  selectWorkerInfo,
  selectWorkerFullAddress,
  selectWorkerProfilePicture
} from '../../features/worker/workerSlice';

import {exitSharp, paperPlaneOutline, closeOutline} from 'ionicons/icons';

import ModalChangeProfilePicture from './ModalChangeProfilePicture';
import ModalChangePassword from './ModalChangePassword';

import { useTranslation } from 'react-i18next';


const ModalUserProfile = ({
    onDismiss,
    }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
        userInfo: any
    }) => {        
    
      const workerInfo = useAppSelector(selectWorkerInfo);
      const workerFullAddress = useAppSelector(selectWorkerFullAddress);
      const workerPicture = useAppSelector(selectWorkerProfilePicture)
      
    // Modal change profile picture
    const [presentProfilePicture, dismissProfilePicture] = useIonModal(ModalChangeProfilePicture, {
      onDismiss: (data: string, role: string) => dismissProfilePicture(data, role),
    }, );

    function openChangeProfilePictureModal() {
      presentProfilePicture({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        },);
    } 

    // Modal change password
    const [presentChangePassword, dismissChangePassword] = useIonModal(ModalChangePassword, {
      onDismiss: (data: string, role: string) => dismissChangePassword(data, role),
    }, );

    function openChangePasswordModal() {
      presentChangePassword({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        },);
    } 

    const [t] = useTranslation("global");

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton className='user-profile__close-button' title={t('Salir')} color="medium" onClick={() => onDismiss(null, 'cancel')}>
                <IonIcon className='user-profile__close' ios={closeOutline} md={closeOutline}/>
              </IonButton>
            </IonButtons>
            <IonTitle>{t('Perfil del usuario')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="ion-padding user-profile">
          {/* <IonIcon slot="start" ios={exitSharp} md={exitSharp} /> */}
          <ul className="user-profile__list">
            <li className="user-profile__item">
              <IonAvatar className='user-profile__avatar'>
                <img src='/assets/favicon/favicon.png' />
              </IonAvatar>
            </li>
            <li className="user-profile__item user-profile__item--name">
              { workerInfo.name ?
              <IonNote className='user-profile__note'>{workerInfo.name} {workerInfo.first_surname} {workerInfo.second_surname}</IonNote>
              : <IonSkeletonText animated={true} style={{width:'210px', height:'20px', margin: '1rem auto 0'}}></IonSkeletonText>
              }
              </li>
            <li className="user-profile__item user-profile__item--email">
            { workerInfo.email ?
              <IonNote className='user-profile__note'>{workerInfo.email}</IonNote>
              : <IonSkeletonText animated={true} style={{width:'200px', height:'15px', margin: '.25rem auto 0'}}></IonSkeletonText>
              }
            </li>
            <li className="user-profile__item user-profile__item--phone">
              <IonIcon slot="start" ios={paperPlaneOutline} md={paperPlaneOutline} />
              <IonNote className='user-profile__note'>{workerInfo.phone}</IonNote>
            </li>
            <li className="user-profile__item user-profile__item--other fake-input">
              <IonLabel className='user-profile__label'>{t('Fecha de Nacimiento')}: </IonLabel>
              <IonNote>
                {
                 workerInfo.birthday ? `${workerInfo.birthday.split('-')[2]} / ${workerInfo.birthday.split('-')[1]} / ${workerInfo.birthday.split('-')[0]}` : '-'
                }
                </IonNote>
            </li>
            <li className="user-profile__item user-profile__item--other fake-input">
              <IonLabel className='user-profile__label'>{t('Dirección')}: </IonLabel>
              <IonNote>
                {workerFullAddress?.street_type}{' '}
                {workerFullAddress?.street}{' '}
                {workerFullAddress?.number}
                {workerFullAddress?.block ? `, ${t('Bloque')}`  : ""}{workerFullAddress.block}
                {workerFullAddress?.stair ? `, ${t('Escalera')}`  : ""}{workerFullAddress.stair}
                {workerFullAddress?.flat ? `, ${t('Piso')}`  : ""}{workerFullAddress.flat}
                {workerFullAddress?.door ? `, ${t('Puerta')}`  : ""}{workerFullAddress.door}
              </IonNote>
            </li>
            
          </ul>

          <button onClick={() => openChangePasswordModal()} className="button-base solid fake-input__button" title={t('Cambiar contraseña')}>{t('Cambiar contraseña')}</button>
        </div>
      </IonPage>
    );
  };


  export default ModalUserProfile