import './Report.css'
import { useState } from 'react';
import { OverlayEventDetail } from '@ionic/core/components';
import {
  useIonModal,
  IonIcon
} from '@ionic/react';
import ModalReportRequest from './modals/ModalReportRequest';
import { calendarClearOutline, documentAttachOutline, imageOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';


const MedicalHistoryDocument : any = (props : any)=>{
    
    const {id, document_name, document_type, signing_date} = props.rep

    const [reportInfo, setReportInfo] = useState<any>({
        report_type: "",
        report_id: "",
        report_name: ""
    })

    // Modal
    const [present, dismiss] = useIonModal(ModalReportRequest, {
        onDismiss: (data: string, role: string) => dismiss(data, role),
        reportInfo: reportInfo
      });
    
    function openModal({file_type, id, document_name}: {file_type:string, id:string, document_name:string}) {
        setReportInfo({
            report_type: file_type,
            report_id: id,
            report_name: document_name
        })
        present({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const [t, i18n] = useTranslation("global");
    
    return(
        <div key={id} className="report med-hist-docu">
            <div className="report__header">
                <div className="report__title">
                    <p>
                        {document_name}
                        <span className='report__date'>
                            {t('Firmado')}: { signing_date?.split('T')[0].split('-')[2]}/{signing_date?.split('T')[0].split('-')[1]}/{signing_date?.split('T')[0].split('-')[0] }
                        </span>
                    </p>
                    <a download title={t('Descargar Documento')} target="_blank" onClick={()=> openModal(props.rep)} className="report__button">
                        <IonIcon aria-hidden="true" className='report__icon' ios={documentAttachOutline} md={documentAttachOutline}></IonIcon>
                    </a>
                </div>
            </div>
            <div className="report__body">
                <div className="report__props">
                    <div className="report__prop">
                        <div className="report__prop--title">
                            {t('Tipo de documento')}:
                        </div>
                        {document_type}
                    </div>
                    {/* <div className="report__prop">
                        <div className="report__prop--title">
                            Fecha de firma: 
                        </div>
                        {signing_date?.split('T')[0]}
                    </div> */}
                </div>
            </div>
        </div>
    )
}



export default MedicalHistoryDocument