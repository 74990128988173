import { IonIcon, IonPage, IonContent, IonItem, IonHeader, IonButtons, IonToolbar, IonMenuButton, IonSelect, IonSelectOption } from "@ionic/react"

import './Home.scss'
import { calendarClearOutline, notificationsOutline, clipboardOutline, heartOutline, paperPlaneOutline, exitOutline } from 'ionicons/icons';

import { setAuthToken   } from '../helpers/setAuthToken';
import { useAppSelector } from '../app/hooks';
import { selectWorker   } from '../features/worker/workerSlice';

import { useTranslation } from 'react-i18next';
import Toolbar from "../components/Toolbar";

interface AppPage {
    url     : string;
    iosIcon : string;
    mdIcon  : string;
    title   : string;
}

const Home:any = ()=>{

    const [t, i18n] = useTranslation("global");

    const appPages: AppPage[] = [
        {
            title   : t('Comunicaciones'),
            url     : '/comunicaciones',
            iosIcon : notificationsOutline,
            mdIcon  : notificationsOutline
        },
        {
            title   : t('Agenda'),
            url     : '/agenda',
            iosIcon : calendarClearOutline,
            mdIcon  : calendarClearOutline
        },
        {
            title   : t('Informes'),
            url     : '/vigilancia',
            iosIcon : clipboardOutline,
            mdIcon  : clipboardOutline
        },
        {
            title   : t('Mi Salud'),
            url     : '/mi-salud',
            iosIcon : heartOutline,
            mdIcon  : heartOutline
        },
        // {
        //     title: t('Contacto'),
        //     url: '/page/Contacto',
        //     iosIcon: paperPlaneOutline,
        //     mdIcon: paperPlaneOutline
        // },
    ];

    const removeToken = ()=>{
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        localStorage.setItem("logged", "false");
        setAuthToken("")
    }

    const worker = useAppSelector(selectWorker);

    return(
        <IonPage>
            <Toolbar title='' />
            <IonContent fullscreen>
                <div className="home">
                    <p className="home__greeting">{t('¡Hola')} { worker.name }!<span>{t('Estás accediendo a tu portal del paciente')}</span></p>
                    <ul className="home__ul">
                        { appPages.map((appPage, index) => {
                        return (
                            <li className="home__li" key={index}>
                                <IonItem routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                                    <div className="home__a">
                                        <span><IonIcon className="home__icon" ios={appPage.iosIcon} md={appPage.mdIcon}></IonIcon></span>
                                        <h3 className="home__title">{appPage.title}</h3>
                                    </div>
                                </IonItem>
                            </li>
                        )})}
                        <li className="home__li exit">
                            <IonItem routerLink="/login" routerDirection="none" lines="none" detail={false} onClick={()=>removeToken()}>
                                <div className="home__a">
                                    <span><IonIcon className="home__icon" ios={exitOutline} md={exitOutline}></IonIcon></span>
                                    <h3 className="home__title">{t('Salir')}</h3>
                                </div>
                            </IonItem>
                        </li>
                    </ul>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Home