import { useState } from 'react';
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonPage,
  IonList,
  IonLabel,
  IonIcon,
  IonNote,
  IonInput,
  IonToast,
  IonItem,
} from '@ionic/react';
import "./ModalChangePassword.css"
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { changePassword } from '../../actions/loginInfo';
import { selectPasswordValidationMessages, selectWrongNewPassword, unsetWrongPasswordValidation } from '../../features/login/loginSlice';
import { closeOutline} from 'ionicons/icons';
import { useTranslation } from 'react-i18next';


const ModalChangePassword = ({
    onDismiss,
    }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
        userInfo: any
    }) => {        

      const [t] = useTranslation("global");
    
      const [oldPassword, setOldPassword] = useState("")
      const [newPassword, setNewPassword] = useState("")
      const [newPasswordConfirm, setNewPasswordConfirm] = useState("")
      const [notPasswordCoincidence, setNotPasswordCoincidence] = useState(false)

      const dispatch = useAppDispatch();
      const wrongPasswordValidation = useAppSelector(selectWrongNewPassword)
      const passwordValidationMessages = useAppSelector(selectPasswordValidationMessages)

      const handleOldPassword = (e:any) => {
        setOldPassword(e.detail.value)
      }

      const handleNewPassword = (e:any) => {
        setNewPassword(e.detail.value)
      }

      const handleNewPasswordConfirm = (e:any) => {
        setNewPasswordConfirm(e.detail.value)
      }

      const handleChangePassword = (e:any) => {
        e.preventDefault()
        dispatch(unsetWrongPasswordValidation())
        if (newPassword !== newPasswordConfirm){
          setNotPasswordCoincidence(true)
        } else {
          dispatch(changePassword({oldPassword, newPassword}))
        }


      }

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton className='user-profile__close-button' title={t('Salir')} color="medium" onClick={() => onDismiss(null, 'cancel')}>
                <IonIcon className='user-profile__close' ios={closeOutline} md={closeOutline}/>
              </IonButton>
            </IonButtons>
            <IonTitle>{t('Cambiar contraseña')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="ion-padding change-psw">
          <form className='modal-form' onSubmit={(e) => handleChangePassword(e)}>
            <ul className="modal-form__ul">
              <li>
                <IonLabel position="stacked">{t('Contraseña antigua')}:</IonLabel>
                <IonInput required type="password" onIonChange={(e) => handleOldPassword(e)} placeholder="********"></IonInput>
              </li>
              <li>
                <IonLabel position="stacked">{t('Contraseña nueva')}:</IonLabel>
                <IonInput required type="password" onIonChange={(e) => handleNewPassword(e)} placeholder="********"></IonInput>
              </li>
              <li>
                <IonLabel position="stacked">
                  {t('Contraseña nueva')}:
                  <span>({t('Confirmación')})</span>
                </IonLabel>
                <IonInput required type="password" onIonChange={(e) => handleNewPasswordConfirm(e)} placeholder="********"></IonInput>
              </li>
            </ul>

            <button className="button-base solid modal-form__submit" type='submit' title={t('Confirmar')} >{t('Confirmar')}</button>
          </form>
          <IonList>
            {
              wrongPasswordValidation ? passwordValidationMessages?.map((validationMessage) => (
                  <IonNote>{validationMessage}</IonNote>
              )) 
              : null
            }
            
          </IonList>
          <IonToast
              isOpen={notPasswordCoincidence}
              onDidDismiss={() => setNotPasswordCoincidence(false)}
              message={t('Las contraseñas no coinciden')}
              duration={2000}
          />
        </div>
      </IonPage>
    );
  };


  export default ModalChangePassword