import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IReportStatus } from '../../actions/getReports';


const initialState: IReportStatus = {
  incorrectPassword: false,
  passwordRequested: false,
  reportDownloaded: false,
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setIncorrectPassword: (state) => {
      state.incorrectPassword = true
    },
    unsetIncorectPassword: (state) => {
      state.incorrectPassword = false
    },
    setPasswordRequested: (state) => {
      state.passwordRequested = true
    },
    unsetPasswordRequested: (state) => {
      state.passwordRequested = false
    },
    setReportDownloaded: (state) => {
      state.reportDownloaded = true
    },
    unsetReportDownloaded: (state) => {
      state.reportDownloaded = false
    }
  },
});

export const { setIncorrectPassword, unsetIncorectPassword, setPasswordRequested, unsetPasswordRequested, setReportDownloaded, unsetReportDownloaded } = reportSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectIncorrectPassword = (state: RootState) => (state.report.incorrectPassword);
export const selectPasswordRequested = (state: RootState) => (state.report.passwordRequested);
export const selectReportDownloaded = (state: RootState) => (state.report.reportDownloaded);

export default reportSlice.reducer;
