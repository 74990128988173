import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IAppointments } from '../../actions/appointmentManagement';


const initialState: IAppointments = {
  appointments: [
    {
      id: 123,
      appointment_type: 'Primera Consulta',
      appointment_decline_reason: '',
      date: '2024-03-15T11:00:00',
      main_reason: 'Alergología',
      medic: 'Dra. Ana Mº Regalado Hernandez',
      medical_center: 'Hospital de la Paz',
      status: 'programmed'
    },
    {
      id: 234,
      appointment_type: 'Revisión',
      appointment_decline_reason: '',
      date: '2024-03-11T15:30:00',
      main_reason: 'Otorrinolaringología',
      medic: 'Dra. Belén López Castaleda',
      medical_center: 'Hospital de la Paz',
      status: 'confirmed'
    },
    {
      id: 345,
      appointment_type: 'Otro',
      appointment_decline_reason: '3',
      date: '2024-03-26T09:45:00',
      main_reason: 'Angiología y Cirugía Vascular',
      medic: 'Dr. Jesús Marchante Marchante',
      medical_center: 'Hospital de la Paz',
      status: 'refused'
    },
    {
      id: 456,
      appointment_type: 'Primera Consulta',
      appointment_decline_reason: '',
      date: '2024-03-15T12:15:00',
      main_reason: 'Aparato Digestivo',
      medic: 'Dr. Jesús García Gonzalez',
      medical_center: 'Hospital de la Paz',
      status: 'programmed'
    },
  ]
};

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    acceptAppointmentState: (state, action) => {
      state.appointments = state.appointments.map((appointment) => {
        if (appointment.id == action.payload) {
          appointment.status = "confirmed"
        }
        return appointment
      })
    },
    rejectAppointmentState: (state, action) => {
      state.appointments = state.appointments.map((appointment) => {
        if (appointment.id == action.payload.id) {
          appointment.status = "refused"
          appointment.appointment_decline_reason = action.payload.reject_reason
        }
        return appointment
      })
    },
    initialAppointments: (state, action) => {
        state.appointments = action.payload
    },
    updateAppointments: (state, action) => {
      state.appointments = [...state.appointments, action.payload]
    }
  },
});

export const { acceptAppointmentState, rejectAppointmentState, initialAppointments, updateAppointments } = appointmentSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAppointments = (state: RootState) => (state.appointment.appointments);
export const selectSortedAppointments = (state: RootState) => {
  const sortedAppointments = [...state.appointment.appointments]
  sortedAppointments.sort((a, b) =>  new Date(a.date).getTime() - new Date(b.date).getTime())
  return sortedAppointments
};

export default appointmentSlice.reducer;
