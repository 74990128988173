import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonNote,
  IonImg,
  IonFab,
  IonFabButton
} from '@ionic/react';
import { Camera, CameraResultType } from '@capacitor/camera';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import "./ModalChangeProfilePicture.css"
import {
  selectWorkerProfilePicture,
} from '../../features/worker/workerSlice';
import { changeProfilePicture } from '../../actions/workerInfo';


const ModalChangeProfilePicture = ({
    onDismiss,
    }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
    }) => {        
    
      const [temporalPicture, setTemporalPicture] = useState<any>()

      const photo = useAppSelector(selectWorkerProfilePicture)
      const dispatch = useAppDispatch()

      const takePicture = async () => { 
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Uri
          });
          var imageUrl = image.webPath;
          // Can be set to the src of an image now

          setTemporalPicture(image)
       }

      const savePicture = async () => {
        dispatch(changeProfilePicture(temporalPicture))
      }

      useEffect(() => {
        setTemporalPicture(photo)
      }, []);

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
                X
              </IonButton>
            </IonButtons>
            <IonTitle>Cambiar foto de perfil</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding request">
        <IonImg style={{ 'border': '1px solid black', 'minHeight': '100px' }} src={temporalPicture} ></IonImg>
          <IonFab color="primary" vertical="bottom" horizontal="center" slot="fixed">
            <IonFabButton color="primary" onClick={() => takePicture()}>
              <IonIcon name="add" />
            </IonFabButton>
          </IonFab>
          <IonButtons>
            <IonButton className='request__button' onClick={() => savePicture()}>Guardar imagen</IonButton>
          </IonButtons> 
        </IonContent>
      </IonPage>
    );
  };


  export default ModalChangeProfilePicture