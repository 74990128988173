import axios from "axios"
// import { setWorkerInfo } from "../features/worker/workerSlice";
import { tokenConfig } from '../helpers/setAuthToken';
import { setIncorrectPassword, setPasswordRequested } from "../features/report/reportSlice";
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer';
import { Capacitor } from "@capacitor/core"
import { HealthAppointments } from "./workerInfo";
import { acceptAppointmentState, rejectAppointmentState, updateAppointments } from "../features/appointment/appointmentSlice";
import { setExpiredSession } from "../helpers/expiredSession"

export interface IAppointments {
    appointments: HealthAppointments[]
}

export const acceptAppointment = (props:any) => async (dispatch:any) => {
    const serverUrl = localStorage.getItem("server_url")

    try {
        // const { data } = await axios.get<any>( 
        //     `${serverUrl}/api/accept_appointment/?appointment=${props.appointment_type}&appointment-id=${props.id}`,
        //     tokenConfig()
        // );

        dispatch(acceptAppointmentState(props.id))

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            setExpiredSession()
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

export const rejectAppointment = (props:any) => async (dispatch:any) => {
    const serverUrl = localStorage.getItem("server_url")

    
    const reject_extra_reason = props.reject_extra_reason && props.reject_extra_reason[0] ? props.reject_extra_reason[0] : "-"

    try {
        // const response = await axios.get<any>( 
        //     `${serverUrl}/api/reject_appointment/?appointment=${props.appointment_type}&appointment-id=${props.id}&appointment-reason=${props.reject_reason}&appointment-extrareason=${reject_extra_reason}`,
        //     tokenConfig(),
        // );

        dispatch(rejectAppointmentState({id : props.id, reject_reason : props.reject_reason}))

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            setExpiredSession()
            dispatch(setIncorrectPassword())
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

export const addAppointment = ( data : HealthAppointments ) => async (dispatch:any) => {
    dispatch(updateAppointments(data))
}