import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import {store} from './app/store'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import global_es from "./translations/es/global.json";
import global_ca from "./translations/ca/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

import { defineCustomElements } from '@ionic/pwa-elements/loader';

const language = localStorage.getItem('language');

i18next.init({
  interpolation: { escapeValue: false },
  lng: language ? language : 'es',
  resources: {
    es: {
      global: global_es,
    },
    ca: {
      global: global_ca,
    },
  },
});

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBpKq8ER7PcLI0NkbBZB7NIpkKufauqv3c",
  authDomain: "appportal-e182b.firebaseapp.com",
  projectId: "appportal-e182b",
  storageBucket: "appportal-e182b.appspot.com",
  messagingSenderId: "658689876287",
  appId: "1:658689876287:web:bfd3671b907b1e8912338a",
  measurementId: "G-CDDKPKNJG6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);
const analytics = getAnalytics(app);

const container = document.getElementById('root');
const root = createRoot(container!);

defineCustomElements(window);

if (process.env.REACT_APP_ADF_LOGIN === 'true') {
  window.location.href = 'https://www.google.es';
} else {
  root.render(
    <React.StrictMode> 
      <I18nextProvider i18n={i18next}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
