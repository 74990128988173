import "./ModalNotificationDetails.scss"

import { IonButton, IonButtons, IonHeader, IonIcon, IonLabel, IonNote, IonPage, IonTitle, IonToolbar, useIonModal, } from '@ionic/react';
import { closeOutline, documentAttachOutline, imageOutline, mailOpenOutline, paperPlaneOutline } from 'ionicons/icons';

import { useTranslation } from 'react-i18next';
import { INotification } from "../../features/notification/interfaces";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import ModalNotificationFiles from "./ModalNotificationFiles";
import { useState } from "react";

const ModalNotificationDetails = ({
    onDismiss, notification
    }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
        notification : INotification
    }) => {       

    const [t] = useTranslation("global");

    const [activeNotificationFiles, setActiveNotificationFiles] = useState({})
    
    const [presentNotificationFiles, dismissNotificationFiles] = useIonModal(ModalNotificationFiles, {
        data: activeNotificationFiles,
        onDismiss: (data: string, role: string) => dismissNotificationFiles(data, role),
    }, );

    function openNotificationFilesModal( data : any ) {
        setActiveNotificationFiles( data )

        presentNotificationFiles({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {},
        },);
    } 

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton className='user-profile__close-button' title={t('Salir')} color="medium" onClick={() => onDismiss(null, 'cancel')}>
                <IonIcon className='user-profile__close' ios={closeOutline} md={closeOutline}/>
              </IonButton>
            </IonButtons>
            <IonTitle>{t('Detalles de la notificación')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="ion-padding details-notif">
            <div className={`request__button ${ notification.status === 'received' ? '' : 'icon' }`}>
                <IonIcon aria-hidden="true" ios={ notification.status === 'received' ? mailOpenOutline : paperPlaneOutline } md={ notification.status === 'received' ? mailOpenOutline : paperPlaneOutline } />
            </div>
            <div className="fake-input">
                <IonLabel position="stacked">{t('Estado')}:</IonLabel>
                <IonNote>{ notification.status === 'received' ? 'Recibida' : 'Enviada' }</IonNote>
            </div>
            <div className="fake-input">
                <IonLabel position="stacked">{t('Fecha de Envío')}:</IonLabel>
                <IonNote>{ notification.sent_date.toLocaleString('es-ES', { day : 'numeric', month: 'long', year: "numeric", hour: 'numeric', minute: 'numeric' }) }</IonNote>
            </div>
            {
                notification.status === 'received' && notification.received_date &&
                (
                    <div className="fake-input">
                        <IonLabel position="stacked">{t('Fecha de Recepcion')}:</IonLabel>
                        <IonNote>{ notification.sent_date.toLocaleString('es-ES', { day : 'numeric', month: 'long', year: "numeric", hour: 'numeric', minute: 'numeric' }) }</IonNote>
                    </div>
                )
            }
            <div className="fake-input">
                <IonLabel position="stacked">{t('Asunto')}:</IonLabel>
                <IonNote>{ notification.subject }</IonNote>
            </div>
            <div className="fake-input">
                <IonLabel position="stacked">{t('Departamento')}:</IonLabel>
                <IonNote>{ notification.department }</IonNote>
            </div>
            <div className="fake-input">
                <IonLabel position="stacked">{t('Tipo')}:</IonLabel>
                <IonNote>{ notification.type }</IonNote>
            </div>
            <div className="fake-input" style={{'marginBottom' : '1rem'}}>
                <IonLabel position="stacked">{t('Mensaje')}:</IonLabel>
                <IonNote>{ notification.message }</IonNote>
            </div>
            {
                notification.images.length === 0 ? 
                (
                    <div className="file no-hover"> 
                        <span className="file__icon">
                            <IonIcon aria-hidden="true" ios={imageOutline} md={imageOutline}></IonIcon>
                        </span>
                        <p className="file__info">
                            <span className="file__type">{t('No hay imágenes adjuntas')}</span>
                        </p>
                    </div>
                ) : 
                (
                    <div className="file" onClick={() => { openNotificationFilesModal({ type: 'images', files: notification.images }) }}> 
                        <span className="file__icon">
                            <IonIcon aria-hidden="true" ios={imageOutline} md={imageOutline}></IonIcon>
                        </span>
                        <p className="file__info">
                            <span className="file__type">{notification.images.length} { notification.images.length === 1 ? t('imagen adjunta') : t('imágenes adjuntas') }</span>
                            <span className='file__name'>{t('Ver imágenes')}</span>
                        </p>
                    </div>
                )
            }
            {
                notification.documents.length === 0 ? 
                (
                    <div className="file no-hover"> 
                        <span className="file__icon">
                            <IonIcon aria-hidden="true" ios={documentAttachOutline} md={documentAttachOutline}></IonIcon>
                        </span>
                        <p className="file__info">
                            <span className="file__type">{t('No hay documentos adjuntos')}</span>
                        </p>
                    </div>
                ) : 
                (
                    <div className="file" onClick={() => { openNotificationFilesModal({ type: 'documents', files: notification.documents }) }}> 
                        <span className="file__icon">
                            <IonIcon aria-hidden="true" ios={documentAttachOutline} md={documentAttachOutline}></IonIcon>
                        </span>
                        <p className="file__info">
                            <span className="file__type">{notification.documents.length} { notification.documents.length === 1 ? t('documento adjunto') : t('documentos adjuntos') }</span>
                            <span className='file__name'>{t('Ver documentos')}</span>
                        </p>
                    </div>
                )
            }
        </div>
      </IonPage>
    );
  };


  export default ModalNotificationDetails