import { Dispatch, SetStateAction, useState } from 'react';
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonPage,
  IonLabel,
  IonToast,
  IonIcon,
  IonInput,
  IonItem
} from '@ionic/react';
import { requestReportPassword, requestReport } from '../../actions/getReports';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectIncorrectPassword, selectPasswordRequested, unsetIncorectPassword, unsetPasswordRequested, unsetReportDownloaded, selectReportDownloaded } from '../../features/report/reportSlice';
import "./ModalReportRequest.css"
import { useTranslation } from 'react-i18next';
import { closeOutline } from 'ionicons/icons';


const ModalReportRequest = ({
    onDismiss,
    reportInfo
    }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
        reportInfo: any
    }) => {       
      
    const [t] = useTranslation("global");
    
    const [password, setPassword] = useState<any>("")
    const incorrectPassword = useAppSelector(selectIncorrectPassword);
    const passwordRequested = useAppSelector(selectPasswordRequested);
    const reportDownloaded = useAppSelector(selectReportDownloaded);

    const dispatch = useAppDispatch();

    const handlePassword = (e:any) => {
        setPassword(e.target.value)
    }

    const handleRequestReportPassword = () => {
        dispatch(requestReportPassword({report_type: reportInfo.report_type, report_id: reportInfo.report_id, report_name: reportInfo.report_name}))
    }

    const handleRequestReport = () => {
        dispatch(requestReport({report_type: reportInfo.report_type, report_id: reportInfo.report_id, report_name: reportInfo.report_name, password: password}))
    }

    const [isPwdRequested, setIsPwdRequested] : [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)
    const [isPwdAvailable, setIsPwdAvailable] : [boolean, Dispatch<SetStateAction<boolean>>] = useState(true)
    
    const handlePwdTimer = ()=>{
      setIsPwdAvailable(false)
      setTimeout(()=>{
        setIsPwdAvailable(true)
      }, 10000)
    }

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton className='user-profile__close-button' title={t('Salir')} color="medium" onClick={() => onDismiss(null, 'cancel')}>
                <IonIcon className='user-profile__close' ios={closeOutline} md={closeOutline}/>
              </IonButton>
            </IonButtons>
            <IonTitle>{t('Solicita tu informe')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="ion-padding request">
          <div className='request__title'>
            {isPwdRequested && 
              <IonLabel position="stacked">{t('Por favor, introduce la contraseña que acabas de recibir en tu email')}</IonLabel>
            }
            <IonInput onClick={() => handleRequestReport()} placeholder={t('Introduce la contraseña')}></IonInput>
          </div>
          <button className="button-base solid modal-form__submit" title={t('Acceder al archivo')}>{t('Acceder al archivo')}</button>
          <IonButton title={t('¿No tienes la contraseña? Solicita una')} className={ isPwdAvailable ? "modal-request__req" : "modal-request__req inactive"} expand="block" onClick={() => {if(isPwdAvailable)handleRequestReportPassword(); setIsPwdRequested(true); handlePwdTimer()}}>{t('¿No tienes la contraseña? Solicita una')}</IonButton>
        </div>
        <IonToast
            isOpen={incorrectPassword}
            onDidDismiss={() => dispatch(unsetIncorectPassword())}
            message={t('La contraseña introducida no es correcta')}
            duration={2000}
            position='middle'
        />
        <IonToast
            isOpen={passwordRequested}
            onDidDismiss={() => dispatch(unsetPasswordRequested())}
            message={t('Se ha solicitado la contraseña. Por favor, revise su correo electrónico')}
            duration={2000}
            position='middle'
        />
        <IonToast
            isOpen={reportDownloaded}
            onDidDismiss={() => dispatch(unsetReportDownloaded())}
            message={t('El archivo se ha guardado en la carpeta de documentos')}
            duration={2000}
            position='middle'
        />
      </IonPage>
    );
  };


  export default ModalReportRequest