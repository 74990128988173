import axios from "axios"
import { tokenConfig } from '../helpers/setAuthToken';
import { setExpiredSession } from "../helpers/expiredSession"
import { setHistoricalVariables } from "../features/historicalVariables/historicalVariablesSlice";

export interface IHistoricalVariables {
    historicalVariables: IHistoricalVariablesData[];
}

interface IHistoricalVariablesData {
    title: string;
    yaxis: IYaxis;
    xaxis: IXaxis;
    values: IValues[];
}

interface IYaxis {
    title: string;
    range: number[];
    tickvals: number[];
    ticktext: string[];
}

interface IXaxis {
    title: string;
}

interface IValues {
    name: string;
    x: string[];
    y: string[];
    color: string;
}

export const getHistoricalVariables = () => async (dispatch:any) => {
    try {
        const serverUrl = localStorage.getItem("server_url")

        // const { data } = await axios.get<any>( 
        //     `${serverUrl}/api/historical_variables/`,
        //     tokenConfig()
        // );

        // dispatch(setHistoricalVariables(data['data']))

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            setExpiredSession()
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}