import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IHistoricalVariables } from '../../actions/historicalVariables';

const initialState: IHistoricalVariables = {
  historicalVariables: [
    {
        "title": "IMC",
        "xaxis": {
            "title": "Fecha"
        },
        "yaxis": {
            "range": [
                10,
                45
            ],
            "title": "",
            "ticktext": [
                "15",
                "17",
                "19",
                "25",
                "30",
                "35",
                "40"
            ],
            "tickvals": [
                15,
                17,
                19,
                25,
                30,
                35,
                40
            ]
        },
        "values": [
            {
                "x": [
                    "2023-10-23",
                    "2024-01-25",
                    "2024-01-31"
                ],
                "y": [
                    "22.22",
                    "18.37",
                    "19.33"
                ],
                "name": "IMC",
                "color": "#FF0000"
            }
        ]
    },
    {
        "title": "Glucosa",
        "xaxis": {
            "title": "Fecha"
        },
        "yaxis": {
            "range": [
                0,
                250
            ],
            "title": "Concentración (mg/dL)",
            "ticktext": [
                "0",
                "50",
                "100",
                "150",
                "200"
            ],
            "tickvals": [
                0,
                50,
                100,
                150,
                200
            ]
        },
        "values": [
            {
                "x": [
                    "2023-09-12",
                    "2023-09-30",
                    "2023-10-23"
                ],
                "y": [
                    "78",
                    "82",
                    "65"
                ],
                "name": "Glucosa",
                "color": "#FF0000"
            }
        ]
    },
    {
        "title": "Tension arterial",
        "xaxis": {
            "title": "Fecha"
        },
        "yaxis": {
            "range": [
                60,
                190
            ],
            "title": "(mmHg)",
            "ticktext": [
                "70",
                "80",
                "90",
                "100",
                "110",
                "120",
                "130",
                "140",
                "150",
                "160",
                "170",
                "180"
            ],
            "tickvals": [
                70,
                80,
                90,
                100,
                110,
                120,
                130,
                140,
                150,
                160,
                170,
                180
            ]
        },
        "values": [
            {
                "x": [
                    "2023-10-23",
                    "2024-01-25",
                    "2024-01-31"
                ],
                "y": [
                    "100",
                    "111",
                    "106"
                ],
                "name": "Max",
                "color": "#FF0000"
            },
            {
                "x": [
                    "2023-10-23",
                    "2024-01-25",
                    "2024-01-31"
                ],
                "y": [
                    "80",
                    "88",
                    "90"
                ],
                "name": "Min",
                "color": "#0000FF"
            }
        ]
    }
]
};

export const historicalVariablesSlice = createSlice({
  name: 'historicalVariables',
  initialState,
  reducers: {
    setHistoricalVariables: (state, action) => {
      state.historicalVariables = action.payload
    },
  },
});

export const { setHistoricalVariables } = historicalVariablesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectHistoricalVariables = (state: RootState) => (state.historicalVariables.historicalVariables);

export default historicalVariablesSlice.reducer;
