import "./ModalCreateNotification.scss"

import { IonButton, IonButtons, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonPage, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToolbar, useIonModal, } from '@ionic/react';
import { addCircleOutline, cameraOutline, closeOutline, documentAttachOutline, imageOutline } from 'ionicons/icons';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useFilePicker, usePhotoGallery } from "../../app/hooks";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import ModalNotificationFiles from "./ModalNotificationFiles";
import { INotificationFile } from "../../features/notification/interfaces";
import { addNotification } from "../../actions/notficationsManagement";


const ModalCreateNotification = ({
    onDismiss,
    }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
        userInfo : any
    }) => {       

    const [t] = useTranslation("global");

    const notificationTypes = [
        { name : 'Accidente', value : 'accident'    },
        { name : 'Riesgo'   , value : 'risk'        },
        { name : 'Mejora'   , value : 'improvement' },
        { name : 'Otro'     , value : 'other'       }
    ]

    const departments = [
        { name : 'Alergología', value : 0},
        { name : 'Anatomía Patológica', value : 1},
        { name : 'Anestesiología y Reanimación', value : 2},
        { name : 'Angiología y Cirugía Vascular', value : 3},
        { name : 'Aparato Digestivo', value : 4},
        { name : 'Cardiología', value : 5},
        { name : 'Cirugía Cardiovascular', value : 6},
        { name : 'Cirugía General y del Aparato Digestivo', value : 7},
        { name : 'Cirugía Oral y Maxilofacial', value : 8},
        { name : 'Cirugía Ortopédica y Traumatología', value : 9},
        { name : 'Cirugía Pediátrica', value : 10},
        { name : 'Cirugía Plástica, Estética y Reparadora', value : 11},
        { name : 'Cirugía Torácica', value : 12},
        { name : 'Dermatología Médico-Quirúrgica y Venereología', value : 13},
        { name : 'Endocrinología y Nutrición', value : 14},
        { name : 'Farmacología Clínica', value : 15},
        { name : 'Geriatría', value : 16},
        { name : 'Hematología y Hemoterapia', value : 17},
        { name : 'Inmunología', value : 18},
        { name : 'Medicina del Trabajo', value : 19},
        { name : 'Medicina Familiar y Comunitaria', value : 20},
        { name : 'Medicina Física y Rehabilitación', value : 21},
        { name : 'Medicina Intensiva', value : 22},
        { name : 'Medicina Interna', value : 23},
        { name : 'Medicina Nuclear', value : 24},
        { name : 'Medicina Preventiva y Salud Pública', value : 25},
        { name : 'Nefrología', value : 26},
        { name : 'Neumología', value : 27},
        { name : 'Neurocirugía', value : 28},
        { name : 'Neurofisiología Clínica', value : 29},
        { name : 'Neurología', value : 30},
        { name : 'Obstetricia y Ginecología', value : 31},
        { name : 'Oftalmología', value : 32},
        { name : 'Oncología Médica', value : 33},
        { name : 'Oncología Radioterápica', value : 34},
        { name : 'Otorrinolaringología', value : 35},
        { name : 'Pediatría y sus Áreas Específicas', value : 36},
        { name : 'Psiquiatría', value : 37},
        { name : 'Radiodiagnóstico', value : 38},
        { name : 'Reumatología', value : 39},
        { name : 'Urología', value : 40},
    ]

    function openAddFilesModal(){
        const fileInput : HTMLElement = document.getElementById('add-files') as HTMLElement
        fileInput.click()
    }
    
    const { photos, setPhotos, takePhoto } = usePhotoGallery();
    const { images, setImages, documents, setDocuments, pickFile } = useFilePicker();

    const [activeNotificationFiles, setActiveNotificationFiles] = useState({})
    
    const [presentNotificationFiles, dismissNotificationFiles] = useIonModal(ModalNotificationFiles, {
        data: activeNotificationFiles,
        onDismiss: (data: INotificationFile[], role: string) => handleDismiss( data )
    }, );

    function handleDismiss( data : INotificationFile[] ){
            if( data.length > 0 ){
                let newImages    : INotificationFile[] = [...images]
                let newPhotos    : INotificationFile[] = [...photos]
                let newDocuments : INotificationFile[] = [...documents]

                data.map( ( file : INotificationFile ) => {
                    newImages    = newImages.filter   ( image    => image.name    !== file.name && image.data    !== file.data )
                    newPhotos    = newPhotos.filter   ( photo    => photo.name    !== file.name && photo.data    !== file.data )
                    newDocuments = newDocuments.filter( document => document.name !== file.name && document.data !== file.data )
                })

                setImages( newImages )
                setDocuments( newDocuments )
                setPhotos( newPhotos )
            }

            dismissNotificationFiles()
    }

    function openNotificationFilesModal( data : any ) {
        setActiveNotificationFiles( data )

        presentNotificationFiles({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {},
        },);
    } 

    const dispatch = useAppDispatch()
    
    function handleFormSubmit( e : React.SyntheticEvent ){
        e.preventDefault();
        const form     = e.target as HTMLFormElement;
        const formData = new FormData(form);

        const typeValue       = formData.get('type') as string;
        const specialityValue = formData.get('speciality') as string;

        const type       = notificationTypes.find( t => t.value === typeValue )!.name
        const speciality = departments.find( t => t.value === +specialityValue )!.name
        const subject    = formData.get('subject')    as string;
        const message    = formData.get('message')    as string;

        const newNotification = {
            type,
            department : speciality,
            subject,
            message,
            images    : [ ...images, ...photos ],
            documents : documents,
            status    : 'sent',
            sent_date : new Date()
        }

        dispatch(addNotification( newNotification ))
        onDismiss(null, 'added')
    }

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton className='user-profile__close-button' title={t('Salir')} color="medium" onClick={() => onDismiss(null, 'cancel')}>
                <IonIcon className='user-profile__close' ios={closeOutline} md={closeOutline}/>
              </IonButton>
            </IonButtons>
            <IonTitle>{t('Crear una comunicación')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="ion-padding create-notif">
            <form action="" onSubmit={(e) => handleFormSubmit(e)} className='modal-form'>
                <ul className="modal-form__ul">
                    <li>
                        <IonLabel>{t('Típo')}*</IonLabel>
                        <IonSelect name="type" value={'accident'} okText={t('Seleccionar Típo')} cancelText={t('Cancelar')} labelPlacement="floating" className='modal-form__select' placeholder="Típo de Comunicación">
                            {
                                notificationTypes.map( type => {
                                    return <IonSelectOption value={ type.value }>{ type.name }</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    </li>
                    <li>
                        <IonLabel>{t('Especialidad')}*</IonLabel>
                        <IonSelect name="speciality" value={0} okText={t('Seleccionar Especialidad')} cancelText={t('Cancelar')} labelPlacement="floating" className='modal-form__select' placeholder="Elegir Especialidad">
                            {
                                departments.map( department => {
                                    return <IonSelectOption value={ department.value }>{ department.name }</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    </li>
                    <li>
                        <IonLabel>{t('Asunto')}*</IonLabel>
                        <IonInput required name="subject" counter={true} maxlength={256} className='modal-form__input' placeholder='Asunto de la Comunicación'></IonInput>
                    </li>
                    <li>
                        <IonLabel>{t('Mensaje')}*</IonLabel>
                        <IonTextarea required name="message" counter={true} maxlength={30000} autoGrow={true} className='modal-form__textarea' placeholder="Escribe tu mensaje"></IonTextarea>
                    </li>
                    <li className="file-input">
                        {/* <input id='add-files' multiple type="file" name="notification__files" style={{display:'none'}} /> */}
                        <button title='Hacer foto (Opcional)' onClick={ takePhoto } type='button' className="button-base modal-form__take-photo">
                            <IonIcon ios={cameraOutline} md={cameraOutline}/>
                            Hacer Fotos
                        </button>
                        <button title='Añadir Archivos Adjuntos (Opcional)' onClick={ pickFile } type='button' className="button-base modal-form__add-files">
                            <IonIcon ios={addCircleOutline} md={addCircleOutline}/>
                            Añadir Archivos Adjuntos
                        </button>
                        
                        { ( images.length > 0 || photos.length > 0 ) &&
                            (
                                <div className="file" onClick={() => { openNotificationFilesModal({ type: 'images', canDelete: true, files: [ ...images , ...photos ] }) }}> 
                                    <span className="file__icon">
                                        <IonIcon aria-hidden="true" ios={imageOutline} md={imageOutline}></IonIcon>
                                    </span>
                                    <p className="file__info">
                                        <span className="file__type">{images.length + photos.length} {t('imágenes adjuntas')}</span>
                                        <span className='file__name'>{t('Ver imágenes')}</span>
                                    </p>
                                </div>
                            )
                        }
                        { documents.length > 0 &&
                            (
                                <div className="file" onClick={() => { openNotificationFilesModal({ type: 'documents', canDelete: true, files: documents }) }}> 
                                    <span className="file__icon">
                                        <IonIcon aria-hidden="true" ios={documentAttachOutline} md={documentAttachOutline}></IonIcon>
                                    </span>
                                    <p className="file__info">
                                        <span className="file__type">{documents.length} {t('documentos adjuntos')}</span>
                                        <span className='file__name'>{t('Ver documentos')}</span>
                                    </p>
                                </div>
                            )
                        }
                    </li>
                </ul>
                <button className="button-base solid modal-form__submit" type='submit' title={t('Enviar Notificación')}>{t('Enviar Notificación')}</button>
            </form>
        </div>
      </IonPage>
    );
  };


  export default ModalCreateNotification