import { useState, useEffect } from 'react';
import {
IonContent,
IonIcon,
IonItem,
IonLabel,
IonList,
IonListHeader,
IonMenu,
IonMenuToggle,
IonNote,
IonSkeletonText,
useIonModal,
IonAvatar
} from '@ionic/react';
import { OverlayEventDetail } from '@ionic/core/components';

import { useLocation } from 'react-router-dom';
import { paperPlaneOutline, exitOutline, heartOutline, notificationsOutline, calendarClearOutline, clipboardOutline, timeOutline } from 'ionicons/icons';
import './Menu.css';
import { setAuthToken } from '../helpers/setAuthToken';

import { useAppSelector } from '../app/hooks';
import {
    selectWorker,
} from '../features/worker/workerSlice';

import { selectAppointments, selectSortedAppointments } from "../features/appointment/appointmentSlice";

import ModalUserProfile from '../components/modals/ModalUserProfile';
import {StatusNotification, StatusTranslation} from "../shared/constants/notifications"
import { useTranslation } from 'react-i18next';

interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const removeToken = ()=>{
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.setItem("logged", "false");
    setAuthToken("")
}

// const Menu: React.FC = ({worker, ...props}:{worker:any}) => {
const Menu: any = () => {

    const [t] = useTranslation("global");

    const appPages: AppPage[] = [
        {
            title   : t('Comunicaciones'),
            url     : '/comunicaciones',
            iosIcon : notificationsOutline,
            mdIcon  : notificationsOutline
        },
        {
            title   : t('Agenda'),
            url     : '/agenda',
            iosIcon : calendarClearOutline,
            mdIcon  : calendarClearOutline
        },
        {
            title   : t('Informes'),
            url     : '/vigilancia',
            iosIcon : clipboardOutline,
            mdIcon  : clipboardOutline
        },
        {
            title   : t('Mi Salud'),
            url     : '/mi-salud',
            iosIcon : heartOutline,
            mdIcon  : heartOutline
        },
        // {
        //     title: t('Contacto'),
        //     url: '/page/Contacto',
        //     iosIcon: paperPlaneOutline,
        //     mdIcon: paperPlaneOutline
        // },
    ];


const location = useLocation();
const worker = useAppSelector(selectWorker);
const healthAppointments = useAppSelector(selectSortedAppointments);

// Modal
const [present, dismiss] = useIonModal(ModalUserProfile, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
}, );

function openModal() {
    present({
        onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        },
    },);
}

return (
    <IonMenu contentId="main" type="overlay">
    <IonContent>
        <IonList id="inbox-list">
        <IonAvatar onClick={()=>openModal()}>
            <img src='/assets/favicon/favicon.png' />
        </IonAvatar>
        { worker.name ?
        <IonListHeader className='user'>{worker.name}</IonListHeader>
        : <IonSkeletonText animated={true} style={{width:'100px', height:'20px', margin: '1rem 0 0 1rem'}}></IonSkeletonText>
        }
        {
            worker.encrypted_personal_data.email ? 
            <IonNote>{worker.encrypted_personal_data.email}</IonNote>
            : <IonSkeletonText animated={true} style={{width:'250px', height:'15px', margin: '.25rem 0 .75rem 1rem'}}></IonSkeletonText>
        }
        <IonNote className='profile'><a title={t('Ver perfil')} onClick={()=>openModal()}>{t('Ver perfil')}</a></IonNote>
        {appPages.map((appPage, index) => {
            return (
            <IonMenuToggle key={index} autoHide={false}>
                <IonItem title={t('Ir a') + ' ' + appPage.title} className={location.pathname === appPage.url && location.pathname !== "/" ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
            </IonMenuToggle>
            );
        })}

        <IonMenuToggle autoHide={false} >
            <IonItem title={t('Salir')} routerLink="/login" routerDirection="none" lines="none" detail={false} onClick={()=>removeToken()}>
            <IonIcon slot="start" ios={exitOutline} md={exitOutline} />
            <IonLabel>{t('Salir')}</IonLabel>
            </IonItem>
        </IonMenuToggle>

        </IonList>
        <div className="center-alert">
            <div className="center-alert__question">
                <p>Tienes una cita hoy a las 15:30<br></br> ¿Estás ya en el centro médico?</p>
                <button onClick={() => { document.querySelector('.center-alert')?.classList.add('confirmed')}} className="button-base solid">Sí, estoy en el centro</button>
            </div>
            <p className="center-alert__confirmed">Tu centro médico ha sido informado</p>
        </div>
        <IonList className='menu_notif__list'>
        {healthAppointments?.length > 0 &&
            <IonListHeader className='notification'>{t('Comunicaciones')}</IonListHeader>
        }
        {healthAppointments.map((healthAppointment: any, index: any)=>{
            if(healthAppointment){

            return(
                <IonItem title={t('Ver Comunicaciones')} key={index} routerLink={'/comunicaciones'} routerDirection="none">
                <IonLabel className={`menu_notif ${StatusNotification[healthAppointment.status]}`}>
                    <span className='menu_notif__type'>{healthAppointment.main_reason}</span>
                    <span className='menu_notif__time'>
                        <span>
                            <IonIcon className='menu_notif__icon' ios={calendarClearOutline} md={calendarClearOutline} />
                            { healthAppointment.date ? `${healthAppointment.date.split('T')[0].split('-')[2]}/${healthAppointment.date.split('T')[0].split('-')[1]}/${healthAppointment.date.split('T')[0].split('-')[0]}` : null }    
                        </span>  
                        <span>
                            <IonIcon className='menu_notif__icon' ios={timeOutline} md={timeOutline} />                        
                            { healthAppointment.date ? healthAppointment.date.split('T')[1].substring(0, healthAppointment.date.split('T')[1].length - 3) : null }
                        </span>
                    </span>
                </IonLabel>
                </IonItem>
            )
            }else{
            return(
                <div key={index}>
                    <h1>
                        {t('Error')}
                    </h1>
                </div>
            )
            }
        })}
        </IonList>
    </IonContent>
    </IonMenu>
);
};

export default Menu;
